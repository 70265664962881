import React, { useRef, useEffect } from 'react';
import CarouseVideo from 'components/CarouselVideo';
import { getThrottledRAF } from 'helpers/layout';
import EventHandler from 'helpers/EventHandler';
import { get } from 'helpers/utilities';
import deviceInfo from 'helpers/deviceInfo';
import { EVENT } from 'config/constants';
import Intersect from 'components/Intersect';
import useBreakpointKey from '../../hooks/useBreakpointKey';

import styles from './styles.scss';

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const Parallax = props => {
  const { speed = 0.3, imageURL, imageAlt, videoURL, grayscale = false, className = '' } = props;
  const breakpointKey = useBreakpointKey();
  const parallaxRef = useRef();
  const parallaxBackgroundRef = useRef();

  const scrollEvent = useRef();
  const throttledRAF = useRef(getThrottledRAF());

  const applyParallax = () => {
    if (parallaxBackgroundRef.current && parallaxRef.current) {
      const scrollY = window.pageYOffset;
      const viewportHeight = window.innerHeight;
      const parentBox = parallaxRef.current.getBoundingClientRect();
      const elementHeight = parallaxBackgroundRef.current.offsetHeight;
      const elementTopFromParent = parallaxBackgroundRef.current.offsetTop;

      const parentTop = scrollY + parentBox.top;
      const elementTop = parentTop + elementTopFromParent;
      const elementCenter = elementTop + elementHeight / 2;

      const scrollViewportCenter = scrollY + viewportHeight / 2;
      const centerDeviation = elementCenter - scrollViewportCenter;
      const translateY = (centerDeviation * speed) / 2;
      if (deviceInfo.isEdge) {
        parallaxBackgroundRef.current.style.top = `calc(${breakpointKey >= 1 ? '-30%' : '-25%'} + ${
          translateY * -1
        }px)`;
      } else {
        parallaxBackgroundRef.current.style.transform = `translate3d(0, ${translateY * -1}px, 0)`;
      }
    }
  };

  const subscribeToScroll = (e = {}) => {
    if (e.isIntersecting && !scrollEvent.current) {
      scrollEvent.current = EventHandler.subscribe(EVENT.SCROLL, () =>
        throttledRAF.current(applyParallax)
      );
    } else if (!e.isIntersecting && scrollEvent.current) {
      EventHandler.unsubscribe(scrollEvent.current);
      scrollEvent.current = null;
    }
  };

  // did mount
  useEffect(() => {
    scrollEvent.current = EventHandler.subscribe(EVENT.SCROLL, () =>
      throttledRAF.current(applyParallax)
    );
    return () => EventHandler.unsubscribe(scrollEvent.current);
  }, []);

  return (
    <Intersect
      config={{
        root: null,
        margin: '0px',
        threshold: [0, 1]
      }}
      onIntersection={e => subscribeToScroll(get(e, '[0]'))}>
      <aside className={`${styles.parallax} ${className}`} ref={parallaxRef}>
        <div className={styles.parallax__background} ref={parallaxBackgroundRef}>
          <CarouseVideo
            imageURL={imageURL}
            imageAlt={imageAlt}
            videoURL={videoURL}
            imageClass={styles.parallax__image}
            grayscale={grayscale}
            imageSizes={imageSizes}
          />
        </div>
      </aside>
    </Intersect>
  );
};

export default Parallax;
