import React from 'react';
import { Background } from 'react-imgix';

const imgixParamsDefault = {
  auto: 'compress,enhance,format',
  dpr: 1
};

const attributeConfigDefaults = {
  src: 'data-src',
  srcSet: 'data-srcset',
  sizes: 'data-sizes'
};

const BackgroundImage = ({
  className = '',
  src,
  ref,
  imgixParams = {},
  sizes = '100vw',
  attributeConfig = {},
  htmlAttributes,
  children
}) => (
  <Background
    className={className}
    src={src}
    ref={ref}
    imgixParams={{ ...imgixParamsDefault, ...imgixParams }}
    sizes={sizes}
    attributeConfig={{ ...attributeConfigDefaults, ...attributeConfig }}
    htmlAttributes={htmlAttributes}>
    {children}
  </Background>
);

export default BackgroundImage;
