import { get, upperFirst } from 'helpers/utilities';

const pageHeaderMediaQuery = `
    videoType
    headerBackground {
        url
        title
        mimeType
        extension
    }
    headerVideoBackgroundDesktop {
        url
        title
    }
    headerVideoAssetBackgroundDesktop {
        url
        title
    }
    headerVideoBackgroundTablet{
        url
        title
    }
    headerVideoAssetBackgroundTablet {
        url
        title
    }
    headerVideoBackgroundMobile {
        url
        title
    }
    headerVideoAssetBackgroundMobile {
        url
        title
    }
`;

export const getHeaderMedia = (data, screenBreakpoint) => {
  const breakpoint =
    screenBreakpoint === 'largeDesktop' || screenBreakpoint === 'smallDesktop'
      ? 'desktop'
      : screenBreakpoint;
  const videoBreakpointOrder = ['desktop', 'tablet', 'mobile'].sort(videoBreakpoint =>
    videoBreakpoint === breakpoint ? -1 : 1
  );
  const isEmbedVideos = get(data, 'videoType') === 'embedVideos';
  const videoProp = isEmbedVideos ? 'headerVideoBackground' : 'headerVideoAssetBackground';
  let video;
  videoBreakpointOrder.some(videoBreakpoint => {
    video = get(data, `${videoProp}${upperFirst(videoBreakpoint)}${isEmbedVideos ? '' : '[0]'}`);
    return video;
  });

  return {
    imageURL: get(data, 'headerBackground[0].url'),
    imageAlt: get(data, 'headerBackground[0].title'),
    videoURL: get(video, 'url')
  };
};

export default pageHeaderMediaQuery;
