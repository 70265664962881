import React from 'react';
import Parallax from 'components/Parallax';
import { getHeaderMedia } from 'queries/pageHeaderMedia';

import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const AboutUsHeader = (props, ref) => {
  const { headerTitle, description, pageType, ...rest } = props;
  const parallaxProps = {
    ...getHeaderMedia(rest, breakpoint),
    imageAlt: ''
  };
  const breakpoint = useBreakpoint();

  return (
    <header
      className={`component articleHeader ${styles['about-us-header']} ${
        pageType && pageType === 'Careers' ? styles['about-us-header--careers'] : ''
      }`}
      ref={ref}>
      <Parallax className={styles['about-us-header__media']} grayscale={true} {...parallaxProps} />
      <div className={`grid-container ${styles['about-us-header__title-wrapper']}`}>
        <div className={styles['about-us-header__title-container']}>
          <h1
            className={styles['about-us-header__title']}
            dangerouslySetInnerHTML={{ __html: headerTitle.content }}
          />
          <p className={styles['about-us-header__description']}>{description}</p>
        </div>
      </div>
    </header>
  );
};

export default React.forwardRef(AboutUsHeader);
